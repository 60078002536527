.game {
  background-image: url("./forest endor background.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

#grogu {
  height: 100vh;
  width: 100vw;
  position: relative;
}

#grogu img {
  position: absolute;
}

#torso {
  z-index: 1;
  width: 300px;
  height: 300px;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
}

#head {
  width: 700px;
  z-index: 0;
  top: calc(50% - 350px);
  left: calc(50% - 350px);
}

#left {
  z-index: 2;
  top: calc(50% - 250px);
  left: calc(50% + 50px);
}

#right {
  z-index: 2;
  top: calc(50% - 200px);
  left: calc(50% - 185px);
}

#ball {
  position: absolute;
  width: 100px;
  transform: translate(-50%, -50%);
  z-index: 3;
  cursor: none;
}

#goback {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 150px;
  z-index: 5;
}

#rock {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translatex(-50%);
  width: 100%;
  z-index: 0;
}
