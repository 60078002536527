.App {
  background-image: url(https://miro.medium.com/max/12480/1*EOgzcNBQYcdPIxZf4wbGyQ.jpeg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-x: hidden;
  padding-top: 50px;
}

.text {
  background-image: linear-gradient(#00111e, #002b4b);
  width: 30%;
  text-align: center;
  color: white;
  padding-bottom: 10px;
  margin-bottom: 50px;
  margin-top: 50px;
  box-shadow: 10px 10px 40px black;
}

html {
  height: 100%;
}

.mando {
  margin-bottom: 100px;
  box-shadow: 10px 10px 40px;
  width: 30%;
  transition: var(--transition-scale);
}

.mando:hover {
  cursor: pointer;
  transform: scale(1.05);
  transform: perspective();
  transition: var(--transition-scale);
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background: #002b4b;
  border: 2px solid #00111e;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background: #00111e;
}

.Grogu {
  height: 321.94px;
  position: relative;
  margin-bottom: 50px;
  box-shadow: 10px 10px 40px black;
}

.Grogu img {
  width: 50vh;
}

.Grogu .regular {
  position: absolute;
  z-index: 1;
  transition: var(--transition-opacity);
}

.Grogu .blur {
  z-index: 0;
}

div.Grogu:hover {
  cursor: pointer;
}

div.Grogu:hover img.regular {
  opacity: 0;
  transition: var(--transition-opacity);
}

div.Grogu:hover #play {
  opacity: 1;
  transition: var(--transition-opacity);
}

#play {
  margin: 0;
  font-weight: 900;
  font-size: 100px;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  color: white;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0s;
}
